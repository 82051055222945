import { cm } from './cm.js';
import { sounds } from './sounds.js';

export default class Navigation {
  constructor() {
    const containerElem = document.querySelector('.navigation');
    this.soundBtn = document.querySelector('.btn-sound');

    this.soundBtn.addEventListener('click', e => {
      e.stopPropagation();
      this.controlSound(this.soundBtn.dataset.sound === 'off');
    });

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState == 'hidden') {
        sounds.bgm.pause();
      } else if (
        document.visibilityState == 'visible' &&
        this.soundBtn.dataset.sound === 'on'
      ) {
        sounds.bgm.play();
      }
    });

    containerElem.addEventListener('click', e => {
      e.preventDefault();

      if (!cm.bgmStarted) {
        document.body.classList.remove('before-start-sound');
        if (this.soundBtn.dataset.sound === 'on') {
          this.controlSound(true);
        }
      }

      const menu = e.target.dataset.menu;
      switch (menu) {
        case 'center':
          cm.player.setPosition({x: 0, z: cm.unit});
          cm.player.rotateToTarget({
            euler: cm.euler,
            x: 0,
            z: 0
          });
          break;
        case 'online-course':
          cm.player.setPosition({x: cm.floor1.x, z: cm.floor1.z + 6});
          cm.player.rotateToTarget({
            euler: cm.euler,
            x: 0,
            z: cm.floor1.z
          });
          break;
        case 'entrance':
          cm.player.setPosition({z: cm.unit*10.5});
          cm.player.rotateToTarget({
            euler: cm.euler,
            x: 0,
            z: 0
          });
          break;
        case 'messages':
          cm.player.setPosition({x: cm.floor2.x - 5, z: cm.floor2.z});
          cm.player.rotateToTarget({
            euler: cm.euler,
            x: cm.floor2.x,
            z: cm.floor2.z
          });
          break;
        case 'vacancy':
          case 'messages':
          cm.player.setPosition({x: cm.floor3.x + 5, z: cm.floor3.z});
          cm.player.rotateToTarget({
            euler: cm.euler,
            x: cm.floor3.x,
            z: cm.floor3.z
          });
          break;
      }
    }, true);
  }

  controlSound(soundOn) {
    if (soundOn) {
      // play
      cm.bgmStarted = true;
      sounds.bgm.play();
      this.soundBtn.dataset.sound = 'on';
    } else {
      // stop
      cm.bgmStarted = false;
      sounds.bgm.pause();
      this.soundBtn.dataset.sound = 'off';
    }
  }
}
