import {
	Mesh,
	PlaneGeometry,
	MeshLambertMaterial,
} from 'three';

export class Grass {
  constructor(info) {
    this.unit = info.unit;
    this.scene = info.scene;
    this.width = info.width || 10 * this.unit;
    this.height = info.height || 10 * this.unit;
    this.x = info.x || 0;
    this.y = info.y || 0.3;
    this.z = info.z || 0;
    this.color = info.color || 'yellowgreen';
    this.intensity = info.intensity || 0.1;
    this.segment = info.segment || 2;
    
    this.geometry = new PlaneGeometry(
      this.width,
      this.height,
      this.width * this.segment,
      this.height * this.segment
    );
    const material = new MeshLambertMaterial({
      color: this.color,
      flatShading: true,
      // depthWrite: false
      // 반투명한 객체를 렌더링할 때는 depthWrite 속성을 비활성화(false)하곤 합니다. 이렇게 하면 반투명한 객체가 깊이 버퍼에 영향을 주지 않아, 뒤에 있는 객체가 올바르게 렌더링됩니다.
    });
    this.mesh = new Mesh(this.geometry, material);
    this.mesh.receiveShadow = true;
    this.mesh.rotation.x = -Math.PI/2;
    this.mesh.position.set(this.x, this.y, this.z);
    this.scene.add(this.mesh);

    // set position array
    this.positionArray = this.geometry.attributes.position.array;
    this.randomArray = [];
    for (let i = 0; i < this.positionArray.length; i += 3) {
      // 정점(Vertex) 한 개의 x, y, z 좌표를 랜덤으로 조정
      // this.positionArray[i] = this.positionArray[i] + (Math.random() - 0.5) * this.intensity;
      this.positionArray[i] += (Math.random() - 0.5) * this.intensity;
      this.positionArray[i + 1] += (Math.random() - 0.5) * this.intensity;
      this.positionArray[i + 2] += (Math.random() - 0.5) * this.intensity;

      this.randomArray[i] = (Math.random() - 0.5) * this.intensity;
      this.randomArray[i + 1] = (Math.random() - 0.5) * this.intensity;
      this.randomArray[i + 2] = (Math.random() - 0.5) * this.intensity;
    }
  }
}

export class Water extends Grass {
  constructor(info) {
    super(info);
    this.mesh.material.transparent = true;
    this.mesh.material.opacity = info.opacity;
    this.mesh.material.needsUpdate = true;
  }

  wave(time) {
		for (let i = 0; i < this.positionArray.length; i += 3) {
			this.positionArray[i] += Math.sin(time + this.randomArray[i] * 100) * 0.0015;
			this.positionArray[i + 1] += Math.sin(time + this.randomArray[i + 1] * 100) * 0.001;
			this.positionArray[i + 2] += Math.sin(time + this.randomArray[i + 2] * 100) * 0.001;
		}

		this.geometry.attributes.position.needsUpdate = true;
  }
}