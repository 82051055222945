import { cm } from './cm.js';
import {
	Mesh,
	PlaneGeometry,
	BoxGeometry,
	SphereGeometry,
	MeshLambertMaterial,
	MeshStandardMaterial,
	MeshBasicMaterial,
	CanvasTexture,
	TextureLoader,
	AnimationMixer,
	DoubleSide
} from 'three';

import {
	Vec3,
	Box,
	Body,
	Quaternion
} from 'cannon-es';

export class MeshObject {
	constructor(info) {
		this.name = info.name;
		this.width = info.width || 1;
		this.height = info.height || 1;
		this.depth = info.depth || 1;
		this.color = info.color || cm.colors.base;
		this.differenceY = info.differenceY || 0.4;
		this.x = info.x || 0;
		this.y = info.y || this.height / 2 + this.differenceY;
		this.z = info.z || 0;
		this.x *= 1; // this.x = this.x * 1;
		this.y *= 1;
		this.z *= 1;
		this.originX = this.x;
		this.originY = this.y;
		this.originZ = this.z;
		this.opacity = info.opacity || 1;
		this.rotationX = info.rotationX || 0;
		this.rotationY = info.rotationY || 0;
		this.rotationZ = info.rotationZ || 0;
		this.originRotationX = this.rotationX;
		this.originRotationY = this.rotationY;
		this.originRotationZ = this.rotationZ;
		this.scaleX = info.scaleX || 1;
		this.scaleY = info.scaleY || 1;
		this.scaleZ = info.scaleZ || 1;

		this.geometry = info.geometry;
		this.materialName = info.materialName;

		this.cannonOnly = info.cannonOnly || false;

		this.container = info.container || info.scene;

		this.cannonShape = info.cannonShape || new Box(new Vec3(
			this.width/2 * this.scaleX,
			this.height/2 * this.scaleY,
			this.depth/2 * this.scaleZ
		));

		this.mass = info.mass || 0;
		this.cannonWorld = info.cannonWorld;
		this.cannonMaterial = info.cannonMaterial;

		this.callback = info.callback;
		this.hasAnimation = info.hasAnimation;

		if (info.modelSrc) {
			// GLB
			info.loader.load(
				info.modelSrc,
				glb => {
					glb.scene.traverse(child => {
						if (child.isMesh) {
							child.castShadow = true;
							// child.receiveShadow = true;
						}
					});
					this.mesh = glb.scene;
					this.mesh.name = this.name;
					this.mesh.scale.set(this.scaleX, this.scaleY, this.scaleZ);
					this.mesh.position.set(this.x, this.y, this.z);
					this.mesh.rotation.set(this.rotationX, this.rotationY, this.rotationZ);
					this.container.add(this.mesh);

					if (this.hasAnimation) {
						this.mixer = new AnimationMixer(this.mesh);
						let animation;
						if (this.name === 'thisweekvideo') {
							animation = this.mixer.clipAction(glb.animations[1]);
						} else {
							animation = this.mixer.clipAction(glb.animations[0]);
						}
						animation.play();
					}

					// Transparent Mesh for Raycasting
					const geometry = this.geometry || new BoxGeometry(this.width, this.height, this.depth);
					this.transparentMesh = new Mesh(
						geometry,
						new MeshBasicMaterial({
							transparent: true,
							opacity: 0,
							color: 'red',
							depthWrite: false
						})
					);
					this.transparentMesh.name = this.name;
					this.transparentMesh.position.set(this.x, this.y, this.z);
					this.transparentMesh.rotation.y = this.rotationY;
					this.container.add(this.transparentMesh);

					if (this.cannonWorld) this.setCannonBody();

					if (this.callback) this.callback();
				},
				xhr => {
					// console.log('loading...');
				},
				error => {
					// console.log('error');
				}
			);
		} else if (info.mapSrc) {
			const geometry = this.geometry || new BoxGeometry(this.width, this.height, this.depth);
			info.loader.load(
				info.mapSrc,
				texture => {
					const material = new MeshLambertMaterial({
						map: texture,
						side: DoubleSide
					});
					this.mesh = new Mesh(geometry, material);
					this.mesh.name = this.name;
					this.mesh.castShadow = true;
					this.mesh.receiveShadow = true;
					this.mesh.position.set(this.x, this.y, this.z);
					this.mesh.rotation.set(this.rotationX, this.rotationY, this.rotationZ);
					this.container.add(this.mesh);

					if (this.cannonWorld) this.setCannonBody();
				}
			);
		} else {
			// Primitives
			if (!this.cannonOnly) {
				const geometry = this.geometry || new BoxGeometry(this.width, this.height, this.depth);
				let material;
				if (this.materialName === 'Standard') {
					material = new MeshStandardMaterial({
						color: this.color,
						flatShading: true,
						roughness: 0.5,
					});
				} else {
					material = new MeshLambertMaterial({
						color: this.color,
						flatShading: true
					});
				}
				this.mesh = new Mesh(geometry, material);
				this.mesh.name = this.name;
				this.mesh.castShadow = true;
				this.mesh.receiveShadow = true;
				if (this.scaleX) this.mesh.scale.x = this.scaleX;
				if (this.scaleY) this.mesh.scale.y = this.scaleY;
				if (this.scaleZ) this.mesh.scale.z = this.scaleZ;
				this.mesh.position.set(this.x, this.y, this.z);
				this.mesh.rotation.set(this.rotationX, this.rotationY, this.rotationZ);
				this.container.add(this.mesh);
			}

			// temp
			// if (this.cannonOnly) {
			// 	const geometry = new BoxGeometry(this.width, this.height, this.depth);
			// 	const material = new MeshLambertMaterial({
			// 		color: 'purple',
			// 		transparent: true,
			// 		opacity: 0.1,
			// 		depthWrite: false
			// 	});
			// 	this.mesh = new Mesh(geometry, material);
			// 	this.mesh.name = this.name;
			// 	this.mesh.position.set(this.x, this.y, this.z);
			// 	this.mesh.rotation.set(this.rotationX, this.rotationY, this.rotationZ);
			// 	this.container.add(this.mesh);
			// }

			if (this.cannonWorld) this.setCannonBody();
		}
	}

	setPosition(x, y, z) {
		this.x = x;
		this.y = y;
		this.z = z;
		this.mesh.position.set(x, y, z);
		if (this.cannonBody) {
			this.cannonBody.position.x = x;
			this.cannonBody.position.y = y;
			this.cannonBody.position.z = z;
		}
	}

	setPositionX(x) {
		this.x = x;
		if (this.mesh) this.mesh.position.x = x;
		if (this.cannonBody) this.cannonBody.position.x = x;
	}

	setPositionY(y) {
		this.y = y;
		if (this.mesh) this.mesh.position.y = y;
		if (this.cannonBody) this.cannonBody.position.y = y;
	}

	setPositionZ(z) {
		this.z = z;
		if (this.mesh) this.mesh.position.z = z;
		if (this.cannonBody) this.cannonBody.position.z = z;
	}

	setCannonRotation(rotationX, rotationY, rotationZ) {
		if (!this.cannonBody) return;

		const rx = rotationX || this.rotationX;
		const ry = rotationY || this.rotationY;
		const rz = rotationZ || this.rotationZ;

		this.rotationX = rx;
		this.rotationY = ry;
		this.rotationZ = rz;

		// rotation: x
		const quatX = new Quaternion();
		const axisX = new Vec3(1, 0, 0);
		quatX.setFromAxisAngle(axisX, rx);

		// rotation: y
		const quatY = new Quaternion();
		const axisY = new Vec3(0, 1, 0);
		quatY.setFromAxisAngle(axisY, ry);

		// rotation: z
		const quatZ = new Quaternion();
		const axisZ = new Vec3(0, 0, 1);
		quatZ.setFromAxisAngle(axisZ, rz);

		const combinedQuat = quatX.mult(quatY).mult(quatZ);
		this.cannonBody.quaternion = combinedQuat;
	}

	setCannonBody() {
		this.cannonBody = new Body({
			mass: this.mass,
			position: new Vec3(this.x, this.y, this.z),
			shape: this.cannonShape,
			material: this.cannonMaterial
		});

		// this.cannonBody.quaternion.setFromAxisAngle(
		// 	new Vec3(0, 1, 0), // y
		// 	this.rotationY
		// );

		this.setCannonRotation();

		this.cannonWorld.addBody(this.cannonBody);
	}
}

export class Door extends MeshObject {
	constructor(info) {
		super(info);
		this.opened = false;
	}

	open() {
		this.setPositionX(this.originX + 0.6);
		this.setPositionZ(0.6);
		this.setCannonRotation(0, -Math.PI, 0);
		this.opened = true;
	}

	close() {
		this.setPositionX(this.originX);
		this.setPositionZ(this.originZ);
		this.setCannonRotation(this.originRotationX, this.originRotationY, this.originRotationZ);
		this.opened = false;
	}
}

export class GBall extends MeshObject {
	constructor(info) {
		super(info);
		
		const parameters = this.geometry.parameters;

		// 상단 부분을 별도의 메쉬로 생성
		const canvasGeometry = new SphereGeometry(
			parameters.radius,
			parameters.widthSegments,
			parameters.heightSegments,
			Math.PI*0.2, Math.PI*0.6,
			Math.PI*0.3, Math.PI*0.3
		);

		// 텍스트 캔버스 생성
		// this.lines = ["Hello!", "Want to build a 3D website like this?", "(This ball was inspired by Gantz.)"];
		this.lines = [
			"Want to learn how to build a 3D website like this?",
			"Click the object floating above! :)",
		];
		this.currentLine = 0; // 현재 표시할 줄
		this.letterIndex = 0; // 현재 표시할 글자의 위치
		this.letterTiming = 0;

		const textCanvas = this.createTextCanvas(cm.colors.gBallText, '"Roboto Condensed", sans-serif', 10);
		// CanvasTexture 생성
		const textTexture = new CanvasTexture(textCanvas);
		// 재질 생성 및 텍스처 적용
		this.canvasMaterial = new MeshLambertMaterial({
			map: textTexture,
			transparent: true
		});

		const topSphere = new Mesh(canvasGeometry, this.canvasMaterial);
		this.mesh.add(topSphere);

		setInterval(() => {
			this.resetCanvas();
		}, 8000);
	}

	resetCanvas() {
		this.letterIndex = 0;
		this.currentLine = 0;
	}

	drawCanvas(delta, time) {
		// 여기서 지우면 깜빡깜빡
		// this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
		const sizeFactor = this.sizeFactor;

		this.factor = 0.2;
		if (delta < 0.011) this.factor = 0.4; // 두배 빠른 컴퓨터
		// if (this.currentLine === 2) {
		// 	this.factor = 0.1;
		// }
		let num = 10*this.factor;

		// this.context.shadowBlur = Math.abs(Math.sin(time*3) * 10);

		this.canvasMaterial.map.needsUpdate = true;

		if (this.letterTiming++ % num === 0) {
			// 여기서 지우면 보통 상태
			this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
			// this.context.font = 'this.fontSizepx Serif';
			// this.context.globalAlpha = 1;
			// 이전 줄들 그리기
			for (let i = 0; i < this.currentLine; i++) {
				this.context.fillText(this.lines[i], this.canvas.width/2, 30*sizeFactor + i*20*sizeFactor);
			}
		
			// 현재 줄 그리기
			const text = this.lines[this.currentLine];
			// if (this.currentLine === 2) {
			// 	this.context.font = '11px Sans-serif';
			// 	this.context.globalAlpha = 0.5;
			// } else {
			// 	this.context.font = 'this.fontSizepx Serif';
			// }
			this.context.fillText(text.substring(0, this.letterIndex), this.canvas.width/2, 30*sizeFactor + this.currentLine*20*sizeFactor);

			if (this.letterIndex < text.length) {
				this.letterIndex++; // 다음 글자로 이동
			} else if (this.currentLine < this.lines.length - 1) {
				this.currentLine++; // 다음 줄로 이동
				this.letterIndex = 0; // 글자 위치 초기화
				// setTimeout(() => {
				// 	this.isDrawingCanvas = false;
				// 	setTimeout(() => {
				// 		this.currentLine = 0;
				// 		this.letterIndex = 0;
				// 		this.isDrawingCanvas = true;
				// 	}, 100);
				// }, 7000);
			}
		}
	}

	createTextCanvas(color, font, size) {
		this.canvas = document.createElement('canvas');
		this.context = this.canvas.getContext('2d');

		this.sizeFactor = 3;
		const sizeFactor = this.sizeFactor;

		this.canvas.width = 256*sizeFactor;
		this.canvas.height = 128*sizeFactor;
	
		// 텍스트 스타일 설정
		this.context.font = `${size*sizeFactor}px ${font}`;
		this.context.textAlign = 'center';
		this.context.textBaseline = 'middle';
		this.context.fillStyle = color;
	
		// 빛나는 효과
		// this.context.shadowBlur = 7;
		// this.context.shadowColor = color;

		// 그리기

		return this.canvas;
	}
}

export class InfoDisplay extends MeshObject {
	constructor(info) {
		super(info);

		this.isReady = true;
		this.sizeFactor = 2;
		this.fontSizeFactor = 2;
		this.fontSize = 20;

		// 상단 부분을 별도의 메쉬로 생성
		const canvasGeometry = new PlaneGeometry(0.5, 1);

		// this.readyText = 'Click to Start';

		// this.lines = [
		// 	"콰자와자콰쿵꽐라콰자콱자불라자",
		// 	"Click the red object floating above.",
		// 	"Thank you for visiting.",
		// 	"I hope you're always Happy :)"];
		this.currentLine = 0; // 현재 표시할 줄
		this.letterIndex = 0; // 현재 표시할 글자의 위치
		this.letterTiming = 0;

		// 텍스트 캔버스 생성
		const textCanvas = this.createTextCanvas('"Roboto Condensed", sans-serif', this.fontSize*this.fontSizeFactor);
		// CanvasTexture 생성
		const textTexture = new CanvasTexture(textCanvas);
		// 재질 생성 및 텍스처 적용
		this.canvasMaterial = new MeshLambertMaterial({
			map: textTexture,
			transparent: true
		});

		const canvasMesh = new Mesh(canvasGeometry, this.canvasMaterial);
		// canvasMesh.rotation.x = -Math.PI/2;
		canvasMesh.position.set(0, 0.2, 0.101);
		this.mesh.add(canvasMesh);
	}

	resetCanvas() {
		this.letterIndex = 0;
		this.currentLine = 0;
	}

	drawCanvas(delta, time) {
		// this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
		// 여기서 칠하고..
		// this.context.fillStyle = cm.colors.yellow;
		// this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

		let currentFontSize;

		if (this.isReady) {
			// const sizeFactor = this.sizeFactor;
			// this.context.fillStyle = cm.colors.gBallText;
			// this.context.fillText(this.readyText, 10, 30*sizeFactor);
			this.lines = [
				'Hello :D',
				'Click here!'
			];
			currentFontSize = this.fontSize*this.sizeFactor*3;
			this.context.font = `bold ${currentFontSize}px "Roboto Condensed", sans-serif`;
		} else {
			this.lines = [
				`"Hey, You Did It!" creates or introduces`,
				'products that cheer your accomplishments.',
				'("Hey, You Did It!" is the name of the brand)',
				'',
				'1.',
				'I\'m a web developer,',
				'so my first product is the online course',
				'for developing a 3D website like this.',
				'Click the floating object next to you :)',
				'I hope that by the end of this course,',
				'you\'ll be able to create',
				'your own stunning 3D website!',
				'',
				'2.',
				'I\'m also in progress of developing',
				'a mobile app helping your accomplishments.',
				'',
				'3.',
				'If you go to the shop on the right,',
				'you\'ll find some products',
				'to cheer yourself or your friends.',
				'',
				'Thank you for visiting.',
				'I hope you\'re always happy :)',
				'',
				'from Junmo Yoo',
			];
			currentFontSize = this.fontSize*this.sizeFactor;
			this.context.font = `${currentFontSize}px "Roboto Condensed", sans-serif`;
		}

		const sizeFactor = this.sizeFactor;
		const fontSizeFactor = this.fontSizeFactor;

		this.factor = 0.2;
		if (delta < 0.011) this.factor = 0.4; // 두배 빠른 컴퓨터
		let num = 5*this.factor;

		this.canvasMaterial.map.needsUpdate = true;

		if (this.letterTiming++ % num === 0) {
			// 여기서 지우면 오묘한 배경 효과
			// this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
			this.context.fillStyle = cm.colors.white;
			this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
			this.context.fillStyle = cm.colors.stamp;

			// 이전 줄들 그리기
			for (let i = 0; i < this.currentLine; i++) {
				this.context.fillText(
					this.lines[i],
					10*sizeFactor,
					currentFontSize/3*sizeFactor + i*currentFontSize/3*sizeFactor*fontSizeFactor
				);
			}
		
			// 현재 줄 그리기
			const text = this.lines[this.currentLine];
			this.context.fillText(
				text.substring(0, this.letterIndex),
				10*sizeFactor,
				currentFontSize/3*sizeFactor + this.currentLine*currentFontSize/3*sizeFactor*fontSizeFactor
			);

			if (this.letterIndex < text.length) {
				this.letterIndex++; // 다음 글자로 이동
			} else if (this.currentLine < this.lines.length - 1) {
				this.currentLine++; // 다음 줄로 이동
				this.letterIndex = 0; // 글자 위치 초기화
			}
		}

		if (this.isReady) {
			this.context.drawImage(
				this.logoImage,
				this.canvas.width*0.5 - 200,
				this.sizeFactor*200,
				400,
				400
			);
		}
	}

	createTextCanvas(font, size) {
		this.canvas = document.createElement('canvas');
		this.context = this.canvas.getContext('2d');

		const sizeFactor = this.sizeFactor;

		this.canvas.width = 360*sizeFactor;
		this.canvas.height = 720*sizeFactor;
	
		// 텍스트 스타일 설정
		this.context.font = `${size*sizeFactor}px ${font}`;
		this.context.textBaseline = 'middle';
		// this.context.fillStyle = cm.colors.gBallText;
		// this.context.textAlign = 'center';
	
		// 빛나는 효과
		// this.context.shadowBlur = 7;
		// this.context.shadowColor = color;

		this.logoImage = new Image();
		this.logoImage.src = '/images/logo-300.png';

		return this.canvas;
	}
}

export class AnimationBoard extends MeshObject {
	constructor(info) {
		super(info);
		const intervalMS = info.intervalMS || 1000/12;
		const loader = info.loader;
		const startIndex = info.textureStartIndex || 0;
		const endIndex = info.textureEndIndex;
		const folder = info.textureImageFolder;
		const extension = info.textureImageExtension;
		const textures = {};

		this.mesh.material.transparent = true;

		for (let i = startIndex; i <= endIndex; i++) {
			loader.load(
				`${folder}/${i}.${extension}`,
				texture => {
					textures[`f${i}`] = texture;
				}
			);
		}

		let currentFrame = 0;
		setInterval(() => {
			this.mesh.material.map = textures[`f${currentFrame}`];
			this.mesh.material.needsUpdate = true;
			currentFrame++;
			if (currentFrame > endIndex) currentFrame = startIndex;
		}, intervalMS);
	}
}

export class CanvasMesh extends MeshObject {
	constructor(info) {
		super(info);
		
		if (info.textureSrc) {
			this.textureSrc = info.textureSrc;
			this.textureWidth = info.textureWidth || 1;
			this.textureHeight = info.textureHeight || 1;
			this.textureX = info.textureX || 0;
			this.textureY = info.textureY || 0;
			this.textureZ = info.textureZ || 0;
			this.textureRotationX = info.textureRotationX || 0;
		}

		this.canvasTextureWidth = info.canvasTextureWidth || 1;
		this.canvasTextureHeight = info.canvasTextureHeight || 1;
		this.canvasWidth = info.canvasWidth || 512;
		this.canvasHeight = info.canvasHeight || 512;
		this.canvasX = info.canvasX || 0;
		this.canvasY = info.canvasY || 0;
		this.canvasZ = info.canvasZ || 0;
		this.canvasRotationX = info.canvasRotationX || 0;
		this.canvasDrawFunction = info.canvasDrawFunction;

		this.canvasText = info.canvasText || [];
	}

	setTexture() {
		const geometry = new PlaneGeometry(this.textureWidth, this.textureHeight);
		const textureLoader = new TextureLoader();
		textureLoader.load(
			this.textureSrc,
			texture => {
				const material = new MeshBasicMaterial({
					map: texture
				});
				const mesh = new Mesh(geometry, material);
				mesh.position.set(this.textureX, this.textureY, this.textureZ);
				mesh.rotation.x = this.textureRotationX;
				mesh.receiveShadow = true;
				this.mesh.add(mesh);
			}
		);
	}

	drawMultilineText(text, x, y, lineHeight) {
    // 텍스트를 줄바꿈으로 분리
    const lines = text.split("\n");

    for (let i = 0; i < lines.length; i++) {
        // 각 줄을 캔버스에 그리기
        this.context.fillText(lines[i], x, y + (i * lineHeight));
    }
	}

	drawCanvas() {
		this.canvasDrawFunction();
	}

	setCanvas() {
		this.canvas = document.createElement('canvas');
		this.context = this.canvas.getContext('2d');

		this.canvas.width = this.canvasWidth;
		this.canvas.height = this.canvasHeight;

		const canvasGeometry = new PlaneGeometry(this.canvasTextureWidth, this.canvasTextureHeight);
		
		const texture = new CanvasTexture(this.canvas);
		// 재질 생성 및 텍스처 적용
		this.canvasMaterial = new MeshLambertMaterial({
			map: texture,
			transparent: true
		});

		const canvasMesh = new Mesh(canvasGeometry, this.canvasMaterial);
		canvasMesh.position.set(this.canvasX, this.canvasY, this.canvasZ);
		canvasMesh.rotation.x = this.canvasRotationX;
		this.mesh.add(canvasMesh);
		
		this.drawCanvas();
	}
}
