import { cm } from './cm.js';
import { sounds } from './sounds.js';
import {
	Mesh,
	BoxGeometry,
	SphereGeometry,
	MeshBasicMaterial,
	MathUtils,
	BackSide
} from 'three';

import {
	Vec3,
	Box,
	Sphere,
	Body,
	Quaternion
} from 'cannon-es';

import gsap from 'gsap';

export class Player {
	constructor(info) {
		this.camera = info.camera;
		this.name = info.name;
		this.width = info.width || 0.5;
		this.height = info.height || 0.5;
		this.depth = info.depth || 0.5;
		this.color = info.color || cm.colors.white;
		this.differenceY = info.differenceY || 0.4;
		this.x = info.x || 0;
		this.y = info.y || this.height / 2 + this.differenceY;
		this.z = info.z || 0;
		this.x *= 1; // this.x = this.x * 1;
		this.y *= 1;
		this.z *= 1;
		this.rotationX = info.rotationX || 0;
		this.rotationY = info.rotationY || 0;
		this.rotationZ = info.rotationZ || 0;

		this.attackable = true;
		this.jumpable = true;

		this.mass = info.mass || 30;
		this.cannonWorld = info.cannonWorld;
		this.cannonMaterial = info.cannonMaterial;
		const geometry = new BoxGeometry(this.width, this.height, this.depth);
		const material = new MeshBasicMaterial({
			transparent: true,
			opacity: 0,
			depthWrite: false
		});
		this.mesh = new Mesh(geometry, material);
		this.mesh.position.set(this.x, this.y, this.z);
		this.mesh.rotation.set(this.rotationX, this.rotationY, this.rotationZ);
		info.scene.add(this.mesh);

		// const armGeometry = new BoxGeometry(0.2, 0.2, 1);
		// const armMaterial = new MeshBasicMaterial({
		// 	color: cm.colors.skin
		// });
		// this.armMesh = new Mesh(armGeometry, armMaterial);

		const armGeometry = new BoxGeometry(0.2, 0.2, 1);
		const armMaterial = new MeshBasicMaterial({
			transparent: true,
			opacity: 0
		});
		this.armMesh = new Mesh(armGeometry, armMaterial);
		this.armMesh.position.y = info.eyeLevel;
		this.mesh.add(this.armMesh);
		
		// this.armShapeMesh = this.armMesh.clone();
		// this.armShapeMesh.position.set(0.4, -0.5, 0.2);
		// // this.armShapeMesh.rotation.reorder('YXZ');
		// this.armShapeMesh.rotation.x = 1.2;
		// this.armShapeMesh.rotation.y = 0.3;
		// this.armMesh.add(this.armShapeMesh);

		info.loader.load(
			'/models/arm.glb',
			glb => {
				this.armShapeMesh = glb.scene;

				this.armShapeMesh.traverse(function (object) {
					if (object.isMesh && object.material) {
						object.renderOrder = 1000;
						object.material.depthWrite = false;
						object.material.depthTest = false;
						object.material.transparent = true;
					}
				});
				
				this.armShapeMesh.position.set(0.4, -0.5, 0.2);
				this.armShapeMesh.rotation.x = 1.2;
				this.armShapeMesh.rotation.y = 0.3;
				this.armMesh.add(this.armShapeMesh);
			}
		);

		this.waterScreen = new Mesh(
			new SphereGeometry(0.5, 12, 12),
			new MeshBasicMaterial({
				color: info.colors.water,
				transparent: true,
				opacity: 0.5,
				side: BackSide
			})
		);
		this.mesh.add(this.waterScreen);
		this.waterScreen.position.y += 1;

		this.setCannonBody();
	}

	setPosition(info) {
		this.x = info.x || 0;
		this.y = info.y || 0.6;
		this.z = info.z || 0;
		this.mesh.position.set(this.x, this.y, this.z);
		this.cannonBody.position.set(this.x, this.y, this.z);
	}

	armShapeDepthSetup() {
		this.armShapeMesh.traverse(function (object) {
			if (object.isMesh && object.material) {
				object.renderOrder = 1000;
				object.material.depthWrite = false;
				object.material.depthTest = false;
			}
		});
	}

	walk(value, direction) {
		// value *= 2; // temp
		if (direction === 'left') {
			this.rotationY -= MathUtils.degToRad(90);
		}
		if (direction === 'right') {
			this.rotationY += MathUtils.degToRad(90);
		}

		this.x += Math.sin(this.rotationY) * value;
		this.z += Math.cos(this.rotationY) * value;
		if (this.cannonBody) {
			this.cannonBody.position.x = this.x;
			this.cannonBody.position.z = this.z;
		}

		cm.playerOrigin.x = this.x;
		cm.playerOrigin.z = this.z;
	}

	walkMobile(value, radian) {
		// value *= 7; // temp
		const angle = this.rotationY + radian + MathUtils.degToRad(90);
		this.x += Math.sin(angle) * value;
		this.z += Math.cos(angle) * value;
		this.cannonBody.position.x = this.x;
		this.cannonBody.position.z = this.z;
	}

	jump() {
		if (!this.jumpable) return;
		if (!cm.navigation) return;
		if (!cm.navigation.soundBtn) return;

		this.cannonBody.linearDamping = 0;
		this.cannonBody.angularDamping = 0;

		this.jumpable = false;

		if (cm.navigation.soundBtn.dataset.sound === 'on') {
			sounds.fart.play();
			setTimeout(() => {
				sounds.jump.play();
			}, 100);
		}

		let value = 1.6;
		if (this.waterScreen.visible) value = 5.5;
		gsap.to(
			this.cannonBody.position,
			{
				duration: 0.3,
				y: this.y + value
			}
		);
		setTimeout(() => {
			this.jumpable = true;
			this.cannonBody.linearDamping = 0.99;
			this.cannonBody.angularDamping = 0.99;
		}, 650);
	}

	setRotation(euler) {
		this.rotationX = euler.x;
		this.rotationY = euler.y;
		this.rotationZ = euler.z;
		this.armMesh.quaternion.setFromEuler(euler);
		this.camera.quaternion.setFromEuler(euler);
	}
	
	rotateToTarget(info) {
		const x = info.x || 0;
		const z = info.z || 0;
		const euler = info.euler;
		euler.x = euler.y = euler.z = 0;
		// console.log(this)
		// console.log('---', z, this.z, x, this.x);
		const targetYAngle = Math.atan2(z - this.z, -x - -this.x) + Math.PI / 2;
		euler.y = targetYAngle;
		// console.log(euler.y);
		this.setRotation(euler);
	}

	attack() {
		if (!this.armShapeMesh) return;
		
		if (this.attackable) {
			this.attackable = false;

			gsap.to(
				this.armShapeMesh.position,
				{
					duration: 0.15,
					x: '-=0.1',
					y: '+=0.2',
					z: '-=1',
					repeat: 1,
					yoyo: true
				}
			);

			gsap.to(
				this.armShapeMesh.rotation,
				{
					duration: 0.15,
					x: '-=0.6',
					y: '+=0.2',
					repeat: 1,
					yoyo: true
				}
			);
			
			setTimeout(() => {
				this.attackable = true;
			}, 500);
		}
	}

	force(target) {
		if (target.cannonBody) {
			target.cannonBody.velocity.x = 0;
			target.cannonBody.velocity.y = 0;
			target.cannonBody.velocity.z = 0;
			target.cannonBody.angularVelocity.x = 0;
			target.cannonBody.angularVelocity.y = 0;
			target.cannonBody.angularVelocity.z = 0;

			let fixer = 5;
			if (target.mass >= 5) {
				fixer = 30;
			}

			const angle = Math.atan2(
				this.z - target.mesh.position.z,
				this.x - target.mesh.position.x
			);
			let ballXPower = -Math.cos(angle) * fixer;
			let ballYPower = fixer/2;
			let ballZPower = -Math.sin(angle) * fixer;

			target.cannonBody.applyImpulse(
				new Vec3(ballXPower, ballYPower, ballZPower),
				new Vec3()
			);
		}
	}

	setWaterScreen(value) {
		this.waterScreen.visible = value;
	}

	setCannonBody() {
		this.cannonBody = new Body({
			mass: this.mass,
			position: new Vec3(this.x, this.y, this.z),
			shape: new Box(new Vec3(this.width/2, this.height/2, this.depth/2)),
			// shape: new Sphere(this.width/2, 6, 6),
			material: this.cannonMaterial,
			linearDamping: 0.99,
			angularDamping: 0.99
		});

		// // rotation: x
		// const quatX = new Quaternion();
		// const axisX = new Vec3(1, 0, 0);
		// quatX.setFromAxisAngle(axisX, this.rotationX);

		// // rotation: y
		// const quatY = new Quaternion();
		// const axisY = new Vec3(0, 1, 0);
		// quatY.setFromAxisAngle(axisY, this.rotationY);

		// // rotation: z
		// const quatZ = new Quaternion();
		// const axisZ = new Vec3(0, 0, 1);
		// quatZ.setFromAxisAngle(axisZ, this.rotationZ);

		// const combinedQuat = quatX.mult(quatY).mult(quatZ);
		// this.cannonBody.quaternion = combinedQuat;

		this.cannonWorld.addBody(this.cannonBody);
	}
}