import { MeshObject } from './MeshObject.js';

export default function trees(scene, unit, colors, gltfLoader) {
  let tree = new MeshObject({
    scene,
    colors,
    name: 'tree',
    loader: gltfLoader,
    // width: 2,
    height: 6,
    // depth: 2,
    x: -5*unit,
    y: 1,
    z: -11*unit,
    modelSrc: '/models/palmtree.glb',
    differenceY: '0',
    callback: () => {
      const range = 40;
      let treeMesh;
      let randomX;
      let randomZ;
      let randomScale;
      for (let i = 0; i < 200; i++) {
        randomX = Math.floor(Math.random()*range - range/2);
        if (randomX > -10.5 && randomX < 10.5) {
          while (randomZ > -10.5 && randomZ < 10.5) {
            randomZ = Math.floor(Math.random()*range - range/2);
          }
        } else {
          randomZ = Math.floor(Math.random()*range - range/2);
        }

        treeMesh = tree.mesh.clone();
        treeMesh.position.x = randomX * unit;
        treeMesh.position.z = randomZ * unit;
        treeMesh.rotation.x = Math.random()*0.05;
        treeMesh.rotation.y = Math.random()*3;
        treeMesh.rotation.z = Math.random()*0.05;

        randomScale = Math.random()*0.3 + 0.8;
        treeMesh.scale.set(randomScale, randomScale, randomScale);
        treeMesh.position.y = randomScale*0.3;
        scene.add(treeMesh);
      }
    }
  });
}
