import { cm } from './cm.js';
import {
	MeshLambertMaterial,
	CanvasTexture
} from 'three';
import { MeshObject } from './MeshObject.js';
import { db } from './myFirebase.js';
import { collection, getDocs, addDoc, query, orderBy, limit } from "firebase/firestore";

export default class MessageWall extends MeshObject {
	constructor(info) {
		super(info);

		this.modalPanel = info.modalPanel;

		this.sizeFactor = 2;
		this.fontSizeFactor = 2;
		this.fontFamily = 'cursive';
		// this.fonts = [
		// 	"Edu AU VIC WA NT Hand",
		// 	"Nanum Pen Script",
		// 	"cursive",
		// ];

		const textCanvas = this.createTextCanvas();
		this.textTexture = new CanvasTexture(textCanvas);
		this.textTexture.needsUpdate = true;
		this.canvasMaterial = new MeshLambertMaterial({
			map: this.textTexture,
			transparent: true,
			// alphaTest: 0.5
		});
		this.mesh.material = this.canvasMaterial;

		this.messages = [];
		this.db = db;
		this.collection = collection;
		this.getDocs = getDocs;
		this.addDoc = addDoc;
		this.query = query;
		this.orderBy = orderBy;
		this.limit = limit;
		this.getMessages();
	}

	createTextCanvas() {
		this.canvas = document.createElement('canvas');
		this.context = this.canvas.getContext('2d');

		this.sizeFactor = 1;
		const sizeFactor = this.sizeFactor;

		this.canvas.width = 500*sizeFactor;
		this.canvas.height = 360*sizeFactor;
	
		this.context.textAlign = 'center';
		this.context.textBaseline = 'middle';
		this.context.fillStyle = cm.colors.white;
		this.context.strokeStyle = cm.colors.orangeyellow;

		return this.canvas;
	}

	getMessages() {
		const messagesQuery = this.query(
			this.collection(this.db, "messages"),
			this.orderBy("createdAt", "desc"),
			this.limit(20)
		);
		const querySnapshot = this.getDocs(messagesQuery);
		querySnapshot.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				this.messages.push(doc.data());
			});
			this.displayMessages();
		});
	}

	async addMessage(messageText) {
		try {
			const docRef = await this.addDoc(this.collection(this.db, 'messages'), {
				message: messageText,
				createdAt: new Date(),
			});
			this.messages.push({ message: messageText, createdAt: new Date() });
			this.modalPanel.close();
			setTimeout(() => {
				document.body.classList.remove('is-loading');
				cm.player.setPosition({x: 12});
				// cm.player.rotateToTarget({
				// 	euler: cm.euler,
				// 	x: 8*cm.unit
				// });
				setTimeout(() => {
					this.displayCurrentMessage();
				}, 1000);
			}, 500);
		} catch (error) {
			console.error('Error adding message: ', error);
		}
	}

	displayMessages() {
		this.context.fillStyle = 'red';
		this.messages.forEach((doc) => {
			this.context.fillStyle = `hsl(${Math.random() * 360}, 100%, 50%)`;
			this.fontSize = Math.ceil(Math.random() * 15) * this.fontSizeFactor + 15;
			// this.context.font = `${this.fontSize}px ${this.fonts[Math.round(Math.random())]}`;
			this.context.font = `${this.fontSize}px ${this.fontFamily}`;
			this.context.fillText(
				doc.message,
				Math.ceil(Math.random() * this.canvas.width*0.6 + this.canvas.width*0.2),
				Math.ceil(Math.random() * this.canvas.height*0.6 + this.canvas.height*0.2)
			);
		});
		this.textTexture.needsUpdate = true;
	}

	displayCurrentMessage() {
		const xPos = Math.ceil(Math.random() * this.canvas.width*0.6 + this.canvas.width*0.2);
		const yPos = Math.ceil(Math.random() * this.canvas.height*0.6 + this.canvas.height*0.2);
		const doc = this.messages[this.messages.length - 1];
		this.fontSize = Math.ceil(Math.random() * 10) * this.fontSizeFactor + 50;
		// this.context.font = `${this.fontSize}px ${this.fonts[Math.round(Math.random())]}`;
		this.context.fillStyle = 'black';
		this.context.fillText(
			doc.message,
			Math.ceil(xPos),
			Math.ceil(yPos)
		);
		this.context.fillStyle = `hsl(${Math.random() * 360}, 100%, 50%)`;
		this.context.fillText(
			doc.message,
			Math.ceil(xPos)-3,
			Math.ceil(yPos)-3
		);
		this.textTexture.needsUpdate = true; // 텍스처 업데이트
	}
}
