import {cm} from './cm.js';
import {firebaseApp} from './myFirebase.js';
import {getFirestore, collection, addDoc} from 'firebase/firestore';

// Firestore Sample
const db = getFirestore(firebaseApp);
// try {
//   const docRef = await addDoc(collection(db, "users"), {
//     first: "Ada",
//     last: "Lovelace",
//     born: 1815
//   });
//   console.log("Document written with ID: ", docRef.id);
// } catch (e) {
//   console.error("Error adding document: ", e);
// }

export function ModalPanel(info = {}) {
	function open(htmlString, callback) {
		const htmlStr = `
			<div data-function="close-panel" class="component-panel-container">
				<button data-function="close-panel" class="btn-close style-2"></button>
				<div class="component-panel">
					${htmlString}
				</div>
			</div>
		`;
		document.getElementById('app').innerHTML = htmlStr;	
		document.body.classList.add('modal-opened');
		if (callback) callback();
		if (info.openCallback) info.openCallback();
	}

	function close() {
		document.getElementById('app').innerHTML = '';
		document.body.classList.remove('modal-opened');
		cm.notToActivateNavigation = false;
		if (info.closeCallback) info.closeCallback();
	}

	return {
		open,
		close
	};
}

// export function GalleryRegistration() {
// 	// const [artwork, setArtwork] = useState({});

// 	const handleSubmitArtwork = async (event) => {
// 		event.preventDefault();

// 		// 입력된 데이터를 폼에서 가져옵니다.
// 		const message = document.getElementById('form-message').value;

// 		// Firestore 데이터베이스에 데이터를 저장합니다.
// 		try {
// 				const docRef = await addDoc(collection(db, 'artworks'), {
// 						message: message
// 				});
// 				console.log('Document written with ID: ', docRef.id);
// 				alert('Artwork registered successfully!');
// 		} catch (e) {
// 				console.error('Error adding document: ', e);
// 				alert('Error adding document: ' + e);
// 		}
// 	}

// 	// return (
// 	// 	<Panel>
// 	// 		<form onSubmit={handleSubmitArtwork}>
// 	// 			<textarea id="form-message" placeholder='message..' />
// 	// 			<button type="submit">Registration</button>
// 	// 		</form>
// 	// 	</Panel>
// 	// );
// }
