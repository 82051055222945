// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCtHmSIRe8WKkAb68QxpzI5FAcLWuxQUmI",
  authDomain: "hey-you-did-it.firebaseapp.com",
  databaseURL: "https://hey-you-did-it-default-rtdb.firebaseio.com",
  projectId: "hey-you-did-it",
  storageBucket: "hey-you-did-it.appspot.com",
  messagingSenderId: "477704863519",
  appId: "1:477704863519:web:eaa7697d8799d62e07ba2a",
  measurementId: "G-SLZNGSXP13"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const db = getFirestore(firebaseApp);

export {
  firebaseApp,
  analytics,
  db
};
