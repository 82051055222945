import { Howl, Howler } from 'howler';
import { cm } from './cm.js';

cm.bgmStarted = false;

export const sounds = {
	bgm: new Howl({
		src: ['/sounds/bgm.mp3'],
  	loop: true,
		html5: true,
		// onplay() {
		// 	document.body.classList.remove('before-start-sound');
		// }
	}),
	jump: new Howl({
		src: ['/sounds/short-swoosh.mp3']
	}),
	fart: new Howl({
		src: ['/sounds/fart.mp3']
	})
}