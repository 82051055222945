import { MeshObject } from './MeshObject.js';

export default function setGuardrails(scene, unit, colors, cannonWorld, defaultCannonMaterial) {
	let guardrail = new MeshObject({
		scene,
		colors,
		// cannonWorld,
		// cannonMaterial: defaultCannonMaterial,
		name: 'guardrail',
		width: 0.1,
		height: 1.1,
		depth: 0.1,
		y: 0.65
	});

	let oneGuardrail;
	// road1
	guardrail.setPositionX(-unit/2 + 0.1);
	guardrail.setPositionZ(-5*unit - unit/4);
	// 기준이 되는 하나 먼저 세팅하고,
	oneGuardrail = guardrail.mesh.clone();
	scene.add(oneGuardrail);
	// 나머지는 루프로
	for (let i = 0; i < (8-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z += unit/2;
		scene.add(oneGuardrail);
	}
	// 기준이 되는 하나 먼저 세팅하고,
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = unit/2 - 0.1;
	oneGuardrail.position.z = -5*unit - unit/4;
	scene.add(oneGuardrail);
	// 나머지는 루프로
	for (let i = 0; i < (8-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z += unit/2;
		scene.add(oneGuardrail);
	}

	// road2
	// 기준이 되는 하나 먼저 세팅하고,
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = 5*unit + unit/4;
	oneGuardrail.position.z = -unit/2 + 0.1;
	scene.add(oneGuardrail);
	// 나머지는 루프로
	for (let i = 0; i < (8-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x -= unit/2;
		scene.add(oneGuardrail);
	}
	// 기준이 되는 하나 먼저 세팅하고,
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = 5*unit + unit/4;
	oneGuardrail.position.z = unit/2 - 0.1;
	scene.add(oneGuardrail);
	// 나머지는 루프로
	for (let i = 0; i < (8-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x -= unit/2;
		scene.add(oneGuardrail);
	}

	// road3
	// 기준이 되는 하나 먼저 세팅하고,
	oneGuardrail = guardrail.mesh.clone();
	oneGuardrail.position.x = -unit/2 + 0.1;
	oneGuardrail.position.z = 5*unit + unit/4;
	scene.add(oneGuardrail);
	// 나머지는 루프로
	for (let i = 0; i < (8-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z -= unit/2;
		scene.add(oneGuardrail);
	}
	// 기준이 되는 하나 먼저 세팅하고,
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = unit/2 - 0.1;
	oneGuardrail.position.z = 5*unit + unit/4;
	scene.add(oneGuardrail);
	// 나머지는 루프로
	for (let i = 0; i < (8-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z -= unit/2;
		scene.add(oneGuardrail);
	}

	// road4
	// 기준이 되는 하나 먼저 세팅하고,
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -5*unit - unit/4;
	oneGuardrail.position.z = -unit/2 + 0.1;
	scene.add(oneGuardrail);
	// 나머지는 루프로
	for (let i = 0; i < (8-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x += unit/2;
		scene.add(oneGuardrail);
	}
	// 기준이 되는 하나 먼저 세팅하고,
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -5*unit - unit/4;
	oneGuardrail.position.z = unit/2 - 0.1;
	scene.add(oneGuardrail);
	// 나머지는 루프로
	for (let i = 0; i < (8-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x += unit/2;
		scene.add(oneGuardrail);
	}

	// roadEntrance
	// 기준이 되는 하나 먼저 세팅하고,
	oneGuardrail = guardrail.mesh.clone();
	oneGuardrail.position.x = -unit/2 + 0.1;
	oneGuardrail.position.z = 9*unit + unit/4;
	scene.add(oneGuardrail);
	// 나머지는 루프로
	for (let i = 0; i < (6-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z -= unit/2;
		scene.add(oneGuardrail);
	}
	// 기준이 되는 하나 먼저 세팅하고,
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = unit/2 - 0.1;
	oneGuardrail.position.z = 9*unit + unit/4;
	scene.add(oneGuardrail);
	// 나머지는 루프로
	for (let i = 0; i < (6-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z -= unit/2;
		scene.add(oneGuardrail);
	}

	// roadLong1
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -6*unit - unit/4;
	oneGuardrail.position.z = -6*unit - unit/2 + 0.1;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x += unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -5*unit - unit/4;
	oneGuardrail.position.z = -6*unit + unit/2 - 0.1;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x += unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = 6*unit + unit/4;
	oneGuardrail.position.z = -6*unit - unit/2 + 0.1;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x -= unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = 5*unit + unit/4;
	oneGuardrail.position.z = -6*unit + unit/2 - 0.1;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x -= unit/2;
		scene.add(oneGuardrail);
	}

	// roadLong2
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = 6*unit - unit/2 + 0.1;
	oneGuardrail.position.z = -5*unit - unit/4;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z += unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = 6*unit + unit/2 - 0.1;
	oneGuardrail.position.z = -6*unit - unit/4;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z += unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = 6*unit - unit/2 + 0.1;
	oneGuardrail.position.z = 5*unit + unit/4;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z -= unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = 6*unit + unit/2 - 0.1;
	oneGuardrail.position.z = 6*unit + unit/4;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z -= unit/2;
		scene.add(oneGuardrail);
	}

	// roadLong3
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -5*unit - unit/4;
	oneGuardrail.position.z = 6*unit - unit/2 + 0.1;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x += unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -6*unit - unit/4;
	oneGuardrail.position.z = 6*unit + unit/2 - 0.1;
	scene.add(oneGuardrail);
	for (let i = 0; i < (12-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x += unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = 5*unit + unit/4;
	oneGuardrail.position.z = 6*unit - unit/2 + 0.1;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x -= unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = 6*unit + unit/4;
	oneGuardrail.position.z = 6*unit + unit/2 - 0.1;
	scene.add(oneGuardrail);
	for (let i = 0; i < (12-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x -= unit/2;
		scene.add(oneGuardrail);
	}

	// roadLong4
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -6*unit - unit/2 + 0.1;
	oneGuardrail.position.z = -6*unit - unit/4;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z += unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -6*unit + unit/2 - 0.1;
	oneGuardrail.position.z = -5*unit - unit/4;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z += unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -6*unit - unit/2 + 0.1;
	oneGuardrail.position.z = 6*unit + unit/4;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z -= unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -6*unit + unit/2 - 0.1;
	oneGuardrail.position.z = 5*unit + unit/4;
	scene.add(oneGuardrail);
	for (let i = 0; i < (10-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z -= unit/2;
		scene.add(oneGuardrail);
	}

	// center 1
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -unit - unit/4;
	oneGuardrail.position.z = -unit - unit/2 + 0.1;
	scene.add(oneGuardrail);
	for (let i = 0; i < (2-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x += unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = unit - unit/4;
	oneGuardrail.position.z = -unit - unit/2 + 0.1;
	scene.add(oneGuardrail);
	for (let i = 0; i < (2-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x += unit/2;
		scene.add(oneGuardrail);
	}

	// center 2
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = unit + unit/2 - 0.1;
	oneGuardrail.position.z = -unit - unit/2 + unit/4;
	scene.add(oneGuardrail);
	for (let i = 0; i < (2-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z += unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = unit + unit/2 - 0.1;
	oneGuardrail.position.z = unit + unit/2 - unit/4;
	scene.add(oneGuardrail);
	for (let i = 0; i < (2-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z -= unit/2;
		scene.add(oneGuardrail);
	}

	// center 3
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -unit - unit/4;
	oneGuardrail.position.z = unit + unit/2 - 0.1;
	scene.add(oneGuardrail);
	for (let i = 0; i < (2-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x += unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = unit - unit/4;
	oneGuardrail.position.z = unit + unit/2 - 0.1;
	scene.add(oneGuardrail);
	for (let i = 0; i < (2-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.x += unit/2;
		scene.add(oneGuardrail);
	}

	// center 4
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -unit - unit/2 + 0.1;
	oneGuardrail.position.z = -unit - unit/2 + unit/4;
	scene.add(oneGuardrail);
	for (let i = 0; i < (2-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z += unit/2;
		scene.add(oneGuardrail);
	}
	oneGuardrail = oneGuardrail.clone();
	oneGuardrail.position.x = -unit - unit/2 + 0.1;
	oneGuardrail.position.z = unit + unit/2 - unit/4;
	scene.add(oneGuardrail);
	for (let i = 0; i < (2-1); i++) {
		oneGuardrail = oneGuardrail.clone();
		oneGuardrail.position.z -= unit/2;
		scene.add(oneGuardrail);
	}
	
	// 손잡이
	let railHandleLong1_1 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong1_1',
		width: unit*5 - 0.2,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: -4*unit,
		y: 1.1,
		z: -6*unit - unit/2 + 0.1
	});
	const railHandleLong1_1_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong1_1_body',
		width: unit*5 - 0.2,
		height: 1,
		depth: 0.2,
		x: -4*unit,
		y: 0.9,
		z: -6*unit - unit/2 + 0.1,
		cannonOnly: true
	});
	const railHandleLong1_2 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong1_2',
		width: unit*5 - 0.2,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: 4*unit,
		y: 1.1,
		z: -6*unit - unit/2 + 0.1
	});
	const railHandleLong1_2_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong1_2_body',
		width: unit*5 - 0.2,
		height: 1,
		depth: 0.2,
		x: 4*unit,
		y: 0.9,
		z: -6*unit - unit/2 + 0.1,
		cannonOnly: true
	});
	const railHandleLong1_3 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong1_3',
		width: unit*5 + 0.2,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: -3*unit,
		y: 1.1,
		z: -6*unit + unit/2 - 0.1
	});
	const railHandleLong1_3_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong1_3_body',
		width: unit*5 + 0.2,
		height: 1,
		depth: 0.2,
		x: -3*unit,
		y: 0.9,
		z: -6*unit + unit/2 - 0.1,
		cannonOnly: true
	});
	const railHandleLong1_4 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong1_4',
		width: unit*5 + 0.2,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: 3*unit,
		y: 1.1,
		z: -6*unit + unit/2 - 0.1
	});
	const railHandleLong1_4_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong1_4_body',
		width: unit*5 + 0.2,
		height: 1,
		depth: 0.2,
		x: 3*unit,
		y: 0.9,
		z: -6*unit + unit/2 - 0.1,
		cannonOnly: true
	});

	const railHandleLong2_1 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong2_1',
		width: 0.032,
		height: 0.032,
		depth: unit*5 + 0.2,
		color: colors.handle,
		x: 6*unit - unit/2 + 0.1,
		y: 1.1,
		z: -3.5*unit + unit/2
	});
	const railHandleLong2_1_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong2_1_body',
		width: 0.2,
		height: 1,
		depth: unit*5 + 0.2,
		x: 6*unit - unit/2 + 0.1,
		y: 0.9,
		z: -3.5*unit + unit/2,
		cannonOnly: true
	});
	const railHandleLong2_2 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong2_2',
		width: 0.032,
		height: 0.032,
		depth: unit*5,
		color: colors.handle,
		x: 6*unit + unit/2 - 0.1,
		y: 1.1,
		z: -3.5*unit - unit/2 + 0.1
	});
	const railHandleLong2_2_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong2_2_body',
		width: 0.2,
		height: 1,
		depth: unit*5,
		x: 6*unit + unit/2 - 0.1,
		y: 0.9,
		z: -3.5*unit - unit/2 + 0.1,
		cannonOnly: true
	});
	const railHandleLong2_3 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong2_3',
		width: 0.032,
		height: 0.032,
		depth: unit*5 + 0.2,
		color: colors.handle,
		x: 6*unit - unit/2 + 0.1,
		y: 1.1,
		z: 3.5*unit - unit/2
	});
	const railHandleLong2_3_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong2_3_body',
		width: 0.2,
		height: 1,
		depth: unit*5 + 0.2,
		x: 6*unit - unit/2 + 0.1,
		y: 0.9,
		z: 3.5*unit - unit/2,
		cannonOnly: true
	});
	const railHandleLong2_4 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong2_4',
		width: 0.032,
		height: 0.032,
		depth: unit*5,
		color: colors.handle,
		x: 6*unit + unit/2 - 0.1,
		y: 1.1,
		z: 3.5*unit + unit/2 - 0.1
	});
	const railHandleLong2_4_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong2_4_body',
		width: 0.2,
		height: 1,
		depth: unit*5,
		x: 6*unit + unit/2 - 0.1,
		y: 0.9,
		z: 3.5*unit + unit/2 - 0.1,
		cannonOnly: true
	});

	const railHandleLong3_1 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong3_1',
		width: unit*5 + 0.2,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: -3*unit,
		y: 1.1,
		z: 6*unit - unit/2 + 0.1
	});
	const railHandleLong3_1_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong3_1_body',
		width: unit*5 + 0.2,
		height: 1,
		depth: 0.2,
		x: -3*unit,
		y: 0.9,
		z: 6*unit - unit/2 + 0.1,
		cannonOnly: true
	});
	const railHandleLong3_2 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong3_2',
		width: unit*5 + 0.2,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: 3*unit,
		y: 1.1,
		z: 6*unit - unit/2 + 0.1
	});
	const railHandleLong3_2_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong3_2_body',
		width: unit*5 + 0.2,
		height: 1,
		depth: 0.2,
		x: 3*unit,
		y: 0.9,
		z: 6*unit - unit/2 + 0.1,
		cannonOnly: true
	});
	const railHandleLong3_3 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong3_3',
		width: unit*6,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: -3.5*unit + 0.1,
		y: 1.1,
		z: 6*unit + unit/2 - 0.1
	});
	const railHandleLong3_3_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong3_3_body',
		width: unit*6,
		height: 1,
		depth: 0.1,
		x: -3.5*unit,
		y: 0.9,
		z: 6*unit + unit/2 - 0.1,
		cannonOnly: true
	});
	const railHandleLong3_4 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong3_4',
		width: unit*6,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: 3.5*unit - 0.1,
		y: 1.1,
		z: 6*unit + unit/2 - 0.1
	});
	const railHandleLong3_4_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong3_4_body',
		width: unit*6,
		height: 1,
		depth: 0.1,
		x: 3.5*unit,
		y: 0.9,
		z: 6*unit + unit/2 - 0.1,
		cannonOnly: true
	});

	const railHandleLong4_1 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong4_1',
		width: 0.032,
		height: 0.032,
		depth: unit*5,
		color: colors.handle,
		x: -6*unit - unit/2 + 0.1,
		y: 1.1,
		z: -3.5*unit - unit/2 + 0.1
	});
	const railHandleLong4_1_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong4_1_body',
		width: 0.1,
		height: 1,
		depth: unit*5,
		x: -6*unit - unit/2 + 0.1,
		y: 0.9,
		z: -3.5*unit - unit/2 + 0.1,
		cannonOnly: true
	});
	const railHandleLong4_2 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong4_2',
		width: 0.032,
		height: 0.032,
		depth: unit*5 + 0.2,
		color: colors.handle,
		x: -6*unit + unit/2 - 0.1,
		y: 1.1,
		z: -3.5*unit + unit/2
	});
	const railHandleLong4_2_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong4_2_body',
		width: 0.1,
		height: 1,
		depth: unit*5 + 0.2,
		x: -6*unit + unit/2 - 0.1,
		y: 0.9,
		z: -3.5*unit + unit/2,
		cannonOnly: true
	});
	const railHandleLong4_3 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong4_3',
		width: 0.032,
		height: 0.032,
		depth: unit*5,
		color: colors.handle,
		x: -6*unit - unit/2 + 0.1,
		y: 1.1,
		z: 3.5*unit + unit/2 - 0.1
	});
	const railHandleLong4_3_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong4_3_body',
		width: 0.1,
		height: 1,
		depth: unit*5,
		x: -6*unit - unit/2 + 0.1,
		y: 0.9,
		z: 3.5*unit + unit/2 - 0.1,
		cannonOnly: true
	});
	const railHandleLong4_4 = new MeshObject({
		scene,
		colors,
		name: 'railHandleLong4_4',
		width: 0.032,
		height: 0.032,
		depth: unit*5 + 0.2,
		color: colors.handle,
		x: -6*unit + unit/2 - 0.1,
		y: 1.1,
		z: 3.5*unit - unit/2
	});
	const railHandleLong4_4_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleLong4_4_body',
		width: 0.1,
		height: 1,
		depth: unit*5 + 0.2,
		x: -6*unit + unit/2 - 0.1,
		y: 0.9,
		z: 3.5*unit - unit/2,
		cannonOnly: true
	});

	const railHandle1_1 = new MeshObject({
		scene,
		colors,
		name: 'railHandle1_1',
		width: 0.032,
		height: 0.032,
		depth: unit*4 + 0.2,
		color: colors.handle,
		x: -unit/2 + 0.1,
		y: 1.1,
		z: -3.5*unit
	});
	const railHandle1_1_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandle1_1_body',
		width: 0.2,
		height: 1,
		depth: unit*4 + 0.2,
		x: -unit/2 + 0.1,
		y: 0.9,
		z: -3.5*unit,
		cannonOnly: true
	});
	const railHandle1_2 = new MeshObject({
		scene,
		colors,
		name: 'railHandle1_2',
		width: 0.032,
		height: 0.032,
		depth: unit*4 + 0.2,
		color: colors.handle,
		x: unit/2 - 0.1,
		y: 1.1,
		z: -3.5*unit
	});
	const railHandle1_2_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandle1_2_body',
		width: 0.2,
		height: 1,
		depth: unit*4 + 0.2,
		x: unit/2 - 0.1,
		y: 0.9,
		z: -3.5*unit,
		cannonOnly: true
	});

	const railHandle2_1 = new MeshObject({
		scene,
		colors,
		name: 'railHandle2_1',
		width: unit*4 + 0.2,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: 3.5*unit,
		y: 1.1,
		z: -unit/2 + 0.1
	});
	const railHandle2_1_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandle2_1_body',
		width: unit*4 + 0.2,
		height: 1,
		depth: 0.2,
		x: 3.5*unit,
		y: 0.9,
		z: -unit/2 + 0.1,
		cannonOnly: true
	});
	const railHandle2_2 = new MeshObject({
		scene,
		colors,
		name: 'railHandle2_2',
		width: unit*4 + 0.2,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: 3.5*unit,
		y: 1.1,
		z: unit/2 - 0.1
	});
	const railHandle2_2_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandle2_2_body',
		width: unit*4 + 0.2,
		height: 1,
		depth: 0.2,
		x: 3.5*unit,
		y: 0.9,
		z: unit/2 - 0.1,
		cannonOnly: true
	});

	const railHandle3_1 = new MeshObject({
		scene,
		colors,
		name: 'railHandle3_1',
		width: 0.032,
		height: 0.032,
		depth: unit*4 + 0.2,
		color: colors.handle,
		x: -unit/2 + 0.1,
		y: 1.1,
		z: 3.5*unit
	});
	const railHandle3_1_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandle3_1_body',
		width: 0.2,
		height: 1,
		depth: unit*4 + 0.2,
		x: -unit/2 + 0.1,
		y: 0.9,
		z: 3.5*unit,
		cannonOnly: true
	});
	const railHandle3_2 = new MeshObject({
		scene,
		colors,
		name: 'railHandle3_2',
		width: 0.032,
		height: 0.032,
		depth: unit*4 + 0.2,
		color: colors.handle,
		x: unit/2 - 0.1,
		y: 1.1,
		z: 3.5*unit
	});
	const railHandle3_2_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandle3_2_body',
		width: 0.2,
		height: 1,
		depth: unit*4 + 0.2,
		x: unit/2 - 0.1,
		y: 0.9,
		z: 3.5*unit,
		cannonOnly: true
	});

	const railHandle4_1 = new MeshObject({
		scene,
		colors,
		name: 'railHandle4_1',
		width: unit*4 + 0.2,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: -3.5*unit,
		y: 1.1,
		z: -unit/2 + 0.1
	});
	const railHandle4_1_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandle4_1_body',
		width: unit*4 + 0.2,
		height: 1,
		depth: 0.2,
		x: -3.5*unit,
		y: 0.9,
		z: -unit/2 + 0.1,
		cannonOnly: true
	});
	const railHandle4_2 = new MeshObject({
		scene,
		colors,
		name: 'railHandle4_2',
		width: unit*4 + 0.2,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: -3.5*unit,
		y: 1.1,
		z: unit/2 - 0.1
	});
	const railHandle4_2_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandle4_2_body',
		width: unit*4 + 0.2,
		height: 1,
		depth: 0.2,
		x: -3.5*unit,
		y: 0.9,
		z: unit/2 - 0.1,
		cannonOnly: true
	});

	const railHandleEntrance_1 = new MeshObject({
		scene,
		colors,
		name: 'railHandleEntrance_1',
		width: 0.032,
		height: 0.032,
		depth: unit*3 - unit/4 + 0.1,
		color: colors.handle,
		x: -unit/2 + 0.1,
		y: 1.1,
		z: 8*unit - unit/4 + 0.2
	});
	const railHandleEntrance_1_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleEntrance_1_body',
		width: 0.2,
		height: 1,
		depth: unit*3 + 0.2,
		x: -unit/2 + 0.1,
		y: 0.9,
		z: 8*unit,
		cannonOnly: true
	});
	const railHandleEntrance_2 = new MeshObject({
		scene,
		colors,
		name: 'railHandleEntrance_2',
		width: 0.032,
		height: 0.032,
		depth: unit*3 - unit/4 + 0.1,
		color: colors.handle,
		x: unit/2 - 0.1,
		y: 1.1,
		z: 8*unit - unit/4 + 0.2
	});
	const railHandleEntrance_2_body = new MeshObject({
		scene,
		colors,
		cannonWorld,
		cannonMaterial: defaultCannonMaterial,
		name: 'railHandleEntrance_2_body',
		width: 0.2,
		height: 1,
		depth: unit*3 + 0.2,
		x: unit/2 - 0.1,
		y: 0.9,
		z: 8*unit,
		cannonOnly: true
	});

	const railHandleCenter_1 = new MeshObject({
		scene,
		colors,
		name: 'railHandleCenter_1',
		width: unit,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: -unit + 0.1,
		y: 1.1,
		z: -unit -unit/2 + 0.1
	});

	const railHandleCenter_2 = new MeshObject({
		scene,
		colors,
		name: 'railHandleCenter_2',
		width: unit,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: unit - 0.1,
		y: 1.1,
		z: -unit -unit/2 + 0.1
	});

	const railHandleCenter_3 = new MeshObject({
		scene,
		colors,
		name: 'railHandleCenter_3',
		width: 0.032,
		height: 0.032,
		depth: unit,
		color: colors.handle,
		x: unit + unit/2 - 0.1,
		y: 1.1,
		z: -unit + 0.1
	});

	const railHandleCenter_4 = new MeshObject({
		scene,
		colors,
		name: 'railHandleCenter_4',
		width: 0.032,
		height: 0.032,
		depth: unit,
		color: colors.handle,
		x: unit + unit/2 - 0.1,
		y: 1.1,
		z: unit - 0.1
	});

	const railHandleCenter_5 = new MeshObject({
		scene,
		colors,
		name: 'railHandleCenter_5',
		width: unit,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: unit - 0.1,
		y: 1.1,
		z: unit + unit/2 - 0.1
	});

	const railHandleCenter_6 = new MeshObject({
		scene,
		colors,
		name: 'railHandleCenter_6',
		width: unit,
		height: 0.032,
		depth: 0.032,
		color: colors.handle,
		x: -unit + 0.1,
		y: 1.1,
		z: unit + unit/2 - 0.1
	});

	const railHandleCenter_7 = new MeshObject({
		scene,
		colors,
		name: 'railHandleCenter_7',
		width: 0.032,
		height: 0.032,
		depth: unit,
		color: colors.handle,
		x: -unit - unit/2 + 0.1,
		y: 1.1,
		z: unit - 0.1
	});

	const railHandleCenter_8 = new MeshObject({
		scene,
		colors,
		name: 'railHandleCenter_8',
		width: 0.032,
		height: 0.032,
		depth: unit,
		color: colors.handle,
		x: -unit - unit/2 + 0.1,
		y: 1.1,
		z: -unit + 0.1
	});
}